import React from "react";
import { Tag } from "antd";
import css, { SerializedStyles } from "@emotion/css";
import { getPlan } from "@todesktop/shared";

interface ISubscriptionStatusLabelProps {
  status: string;
  planId: string;
  showTitle?: boolean;
  labelCss?: SerializedStyles;
}
const SubscriptionStatusLabel: React.FC<ISubscriptionStatusLabelProps> = ({
  status,
  labelCss,
  showTitle = true,
  planId,
}) => {
  if (!status) {
    return null;
  }
  const color = (() => {
    switch (status) {
      case "trialing":
        return "orange";
      case "active":
        return "green";
      case "cancelled":
      case "canceled":
        return "red";
      default:
        return "red";
    }
  })();

  const statusUppercaseFirstLetter =
    status.charAt(0).toUpperCase() + status.slice(1);

  const statusCss = css`
    && {
      margin-left: 5px;
      font-size: 10px;
      padding: 0 7px;
      line-height: 1.4;
      ${labelCss}
    }
  `;

  return (
    <Tag
      color={color}
      css={statusCss}
      title={showTitle ? `Subscription status: ${status}` : null}
    >
      {statusUppercaseFirstLetter} ({getPlan({ planId })?.label || "Unknown"})
    </Tag>
  );
};

export default SubscriptionStatusLabel;
