import firebase from "firebase/app";
import "firebase/storage";

import {
  CustomMacCodeSign,
  CustomNotarizationApiKeyAuth,
  CustomNotarizationPasswordAuth,
  CustomNotarizationPasswordHsmAuth,
  CustomWindowsCodeSignEV,
  CustomWindowsCodeSignFile,
  IApp,
  IAppFromServerProps,
  IAppIcon,
  IUser,
  IWindowOptions,
  PortalConfigKey,
  WindowsEVOnboardingProvider,
} from "@todesktop/shared";
import {
  createDefaultDesktopApp,
  DeepPartial,
  defaultIcon,
  CertPlatform,
} from "../types";
import merge from "lodash.merge";
import {
  update,
  store,
  selectedApp,
  IGlobalState,
  AppUser,
  selectedAppUser,
  selectAppFromID,
} from "../../store";
import { createAsyncAction, successResult, errorResult } from "pullstate";
import {
  saveUserApplicationData,
  updateUserApplicationData,
  getUserAppDoc,
  getUserDeletedAppDoc,
} from "../firebase";
import { updateUIState } from "./uistate";
import { history, IAppFieldsContext } from "../util";
import { track } from "../util/analytics";
import { message } from "antd";
import { subscribeToBuildCollectionUpdates } from "./builds";
import { UploadFile } from "antd/lib/upload/interface";
import { callFirebaseFunction } from "../firebase/functions";
import { firestore } from "../firebase/firestore";

export type LegacyCustomMacCodeSign = Exclude<
  CustomMacCodeSign,
  { type: "hsm" }
>;

const globalAny: any = global;
const storage = firebase.storage();

export const selectApp = ({ id }: IAppFromServerProps | IApp) => {
  update((draft) => {
    draft.selectedApp = id;
  });

  subscribeToBuildCollectionUpdates(id);
};

export const addApp = (app: IApp, owner: AppUser) => {
  const { apps } = store.getRawState();
  const appAlreadyExists = apps.some(
    (existingApp) => existingApp.id === app.id
  );
  if (appAlreadyExists) {
    return;
  }

  // use placeholder icon if no icon present
  if (!app.icon) {
    app.icon = defaultIcon;
  }

  update((draft) => {
    draft.apps.push(app);
    draft.appToUser[app.id] = owner;
  });
};

export const addAppFromServer = async (
  appServerProps: IAppFromServerProps,
  icons?: IAppIcon[]
) => {
  const { user } = store.getRawState();

  const app = createDefaultDesktopApp(appServerProps);

  if (user) {
    addApp(app, { id: user.id, label: user.firstName, isOwner: true });
    const saveResult = await saveApplication.run({ id: user.id, app: app });
    if (saveResult.error) {
      return;
    }
  } else {
    addApp(app, { id: "", isOwner: true, label: "Anonymous" });
  }

  selectApp(app);
};

export const saveApplication = createAsyncAction<{
  id: string;
  app: IApp;
}>(async ({ id, app }) => {
  try {
    saveUserApplicationData(id, app.id, app);

    return successResult();
  } catch (error) {
    return errorResult(error);
  }
});

export const updateFirestoreApp = async (
  props: DeepPartial<IApp>,
  appHasChanged = true
) => {
  const state = store.getRawState();
  const { id } = selectedApp(state);
  if (!id) {
    throw new Error("Tried updating selected app but no app is selected");
  }
  if (state && state.user && state.user.id) {
    const app = selectedApp(state);
    const user = selectedAppUser(state);

    if (app.meta && appHasChanged && !app.meta.isAppChanged) {
      await updateUserApplicationData(user.id, id, {
        meta: {
          isAppChanged: true,
        },
      });
    }
    await updateUserApplicationData(user.id, id, props);
  } else {
    updateAppWithMerge(props);
  }
};

export const updateAndDispatchApp = async (
  appId: string,
  props: DeepPartial<IApp>
) => {
  const state = store.getRawState();
  const user = state.appToUser[appId];

  await updateUserApplicationData(user.id, appId, props);
  update((draft) => {
    const app = draft.apps.find((app) => app.id === appId);
    if (app) merge(app, props);
  });
};

export const updateWindowOptions = (props: DeepPartial<IWindowOptions>) => {
  return updateFirestoreApp({ windowOptions: props } as any);
};

export const updateAppWithMerge = (props: DeepPartial<IApp>) => {
  update((draft) => {
    const app = selectedApp(draft);
    merge(app, props);
  });
};

export const changeOSPreview = (newOs: IGlobalState["previewScreenshotOS"]) => {
  update((draft) => {
    draft.previewScreenshotOS = newOs;
  });
};

export const updateAppLiveField = (field) => (value) => {
  const { id } = selectedApp(store.getRawState());
  update((draft) => {
    draft.liveFields[`${id}-${field}`] = value;
  });
};

export const setShouldCreateSubWithoutBuild = (status: boolean) => {
  update((draft) => {
    draft.shouldCreateSubWithoutBuild = status;
  });
};

export const newApp = () => {
  if (globalAny && globalAny.forceUnblock) {
    globalAny.forceUnblock();
  }
  updateUIState("new-app");
};

export const deleteApp = async (user, app) => {
  const batch = firestore.batch();
  batch.set(getUserDeletedAppDoc(user.id, app.id), app);
  batch.delete(getUserAppDoc(user.id, app.id));
  await batch.commit();

  history.push("/");

  const state = store.getRawState();
  const currentAppIndex = state.apps.indexOf(selectedApp(state));

  update((draft) => {
    draft.apps.splice(currentAppIndex, 1);
    draft.selectedApp = draft.apps[0].id;
  });
};

export const deleteFileByUrl = async (certUrl: string) => {
  try {
    await storage.refFromURL(certUrl).delete();
  } catch (err) {
    message.error(err.message, 3);
  }
};

const deleteWindowsCertFromHSM = () => {
  const state = store.getRawState();
  const app = selectedApp(state);
  if (app.customWindowsCodeSign && app.customWindowsCodeSign.hsmCertName) {
    try {
      const deleteCert = callFirebaseFunction("deleteWindowsCodeSigningCert");
      deleteCert({
        appId: app.id,
        certName: app.customWindowsCodeSign.hsmCertName,
      });
    } catch (err) {
      // This is just house keeping, we don't need to throw an error.
    }
  }
};

export const updateWindowsCert = (
  customWindowsCodeSign: CustomWindowsCodeSignFile | CustomWindowsCodeSignEV
) => {
  // Ordering below is important. Delete old cert from HSM first then update new cert.
  deleteWindowsCertFromHSM();
  updateFirestoreApp({ customWindowsCodeSign });
};

export const updateMacCert = async (
  appId: string,
  password: string,
  pfxBase64: string
) => {
  const addStandardCodeSigningCert = callFirebaseFunction(
    "addStandardCodeSigningCert"
  );
  await addStandardCodeSigningCert({
    appId,
    password,
    pfxBase64,
    type: "mac",
  });
  await updateFirestoreApp({
    customMacCodeSign: {
      type: "hsm",
      certName: null,
      certPassword: null,
      certUrl: null,
    } as CustomMacCodeSign,
  });
};

export const updateMacNotarization = async (
  appId: string,
  secret: string,
  notarizationData:
    | CustomNotarizationApiKeyAuth
    | CustomNotarizationPasswordHsmAuth
) => {
  const addAppSecret = callFirebaseFunction("addAppSecret");

  // Load old plain password to save it to HSM later (
  const app = selectAppFromID(appId)(store.getRawState());
  const oldNotarization = app?.customNotarization as CustomNotarizationPasswordAuth;
  const oldPassword = oldNotarization?.appleIdPassword;
  const isOldPasswordCleanupRequired =
    typeof oldPassword === "string" && oldPassword.length > 0;
  if (isOldPasswordCleanupRequired && !secret) {
    secret = oldPassword;
  }

  switch (notarizationData.type) {
    case "hsm": {
      await updateFirestoreApp({
        customNotarization: {
          appleId: notarizationData.appleId,
          teamId: notarizationData.teamId,
          type: "hsm",
        },
      });
      if (typeof secret === "string" && secret.length > 0) {
        await addAppSecret({
          appId,
          key: "mac-notarization-appSpecificPassword",
          value: secret,
        });
      }
      break;
    }

    case "hsm-api": {
      await updateFirestoreApp({
        customNotarization: {
          appleApiKeyId: notarizationData.appleApiKeyId,
          appleApiIssuer: notarizationData.appleApiIssuer,
          type: "hsm-api",
        },
      });
      if (typeof secret === "string" && secret.length > 0) {
        await addAppSecret({
          appId,
          key: "mac-api-key",
          value: secret,
        });
      }
      break;
    }

    default: {
      throw new Error("Invalid notarization type");
    }
  }

  // Keep this at the end to make sure secret is saved to HSM before
  if (isOldPasswordCleanupRequired) {
    await updateFirestoreApp({
      customNotarization: {
        appleIdPassword: firebase.firestore.FieldValue.delete() as undefined,
      },
    });
  }
};

export const removeMacCert = async (
  certUrl: LegacyCustomMacCodeSign["certUrl"]
) => {
  await deleteFileByUrl(certUrl);
  updateFirestoreApp({
    customMacCodeSign: firebase.firestore.FieldValue.delete() as any,
    customNotarization: firebase.firestore.FieldValue.delete() as any,
  });
};

export const removeWindowsCert = async () => {
  updateFirestoreApp({
    customWindowsCodeSign: firebase.firestore.FieldValue.delete() as any,
  });
};

export const removePlatformCert = async (
  platform: CertPlatform,
  { customWindowsCodeSign, customMacCodeSign }: IApp
) => {
  if (platform === CertPlatform.Mac && customMacCodeSign) {
    if (customMacCodeSign.type === "hsm") {
      throw new Error(
        "Editing of HSM certificates are currently not supported on Mac. Please contact support for assistance."
      );
    }
    await removeMacCert(customMacCodeSign.certUrl);
  } else if (platform === CertPlatform.Windows && customWindowsCodeSign) {
    await removeWindowsCert();
  }
};

export const updateWindowsEVOnboardingProvider = (
  provider: WindowsEVOnboardingProvider
) => {
  updateFirestoreApp(
    {
      meta: {
        // don't reset the tempHSMCertName or hasGeneratedCert step
        // if they choose to change the provider
        windowsEVOnboarding: {
          provider,
          hasChosenProvider: false,
          hasOrderedCert: false,
          hasBeenVerified: false,
          hasUploadedCert: false,
        },
      },
    },
    false
  );
};

export const beginReplacingWindowsCert = () =>
  updateFirestoreApp(
    {
      meta: {
        /**
         * Once a user is ready to replace their certificate, they'll need to
         * generate a new cert (which will in turn return a tempHSMCertName).
         * To enable this on the UI, we'll reset the tempHSMCertName and hasGeneratedCert
         * values. THe other values can remain the same.
         */
        windowsEVOnboarding: {
          tempHSMCertName: "",
          hasGeneratedCert: false,
        },
      },
    },
    false
  );

export const updateWindowsEVOnboardingStep = (id: string, checked: boolean) =>
  updateFirestoreApp(
    {
      meta: {
        windowsEVOnboarding: {
          [id]: checked,
        },
      },
    },
    false
  );

export const beginBuildProcess = async (
  context: IAppFieldsContext,
  user: IUser,
  eventProperties: { [key: string]: any } = {}
) => {
  if (await context.verifyFields()) {
    setShouldCreateSubWithoutBuild(false);
    updateUIState(user ? "choose-plan" : "convert-login");
    track({ event: "Began New Build Process", properties: eventProperties });
  } else {
    message.error(
      "Please fix the errors (highlighted in red) before building your app"
    );
  }
};

export const handleFileChange = (uploadCallback, uploadTask) => (
  params: any
) => {
  uploadCallback(params);

  return {
    abort: () => {
      abandonFileUpload(uploadTask);
    },
  };
};

export const isFileChangeError = (file: UploadFile) => {
  const { error } =
    file.response && file.response.files && file.response.files[0]
      ? file.response.files[0]
      : { error: undefined };

  if (error) {
    message.error(error, 3);
    return true;
  }

  return false;
};

export const isFileUploadError = (error: Error, onError: any) => {
  if (error) {
    message.error(error.message, 3);
    onError(error);
  }
};

export const abandonFileUpload = (
  firebaseUploadTask: firebase.storage.UploadTask
) => {
  if (firebaseUploadTask && firebaseUploadTask.cancel) {
    firebaseUploadTask.cancel();
  }
};

export const toBase64 = (file: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => resolve(btoa(event.target.result as string));
    reader.onerror = (event) => reject(event.target.error);
    reader.readAsBinaryString(file);
  });

export const toText = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

interface CustomerPortalProps {
  configuration?: PortalConfigKey; // todo: replace with enum
  returnUrl?: string;
  flowData?: {
    type: "subscription_update";
    subscription_update: {
      subscription: string;
    };
  };
}

export async function createCustomerPortalSession(
  props: CustomerPortalProps = {}
) {
  const sessionUrl = await callFirebaseFunction("createCustomerPortalSession")({
    returnUrl: location.href,
    ...props,
  });

  window.location.replace(sessionUrl.data);
}

interface StripeCheckoutProps {
  successUrl?: string;
  cancelUrl?: string;
  priceId: string;
  appId: string;
}

export async function createCustomerCheckoutSession(
  props: StripeCheckoutProps
) {
  const sessionUrl = await callFirebaseFunction("createStripeCheckoutSession")({
    successUrl: location.href,
    cancelUrl: location.href,
    ...props,
  });

  window.location.replace(sessionUrl.data);
}
