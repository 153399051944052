export const professionalPlanFeatureKeys = [
  {
    key: "shouldCreateAppXFiles",
    value: "Create Windows App Store Artifacts",
  },
  {
    key: "shouldCreate32BitWindowsArtifacts",
    value: "Create 32 Bit Windows Artifacts",
  },
  {
    key: "shouldCreateDebianPackages",
    value: "Create Debian Packages",
  },
  {
    key: "shouldCreateMacAppStoreFiles",
    value: "Create Mac App Store Artifacts",
  },
  {
    key: "shouldCreateMacPKG",
    value: "Create Mac PKG installers",
  },
  {
    key: "shouldCreateMSIInstallers",
    value: "Create MSI Installers",
  },
  {
    key: "shouldCreateRPMPackages",
    value: "Create RPM Packages",
  },
  {
    key: "shouldCreateSnapFiles",
    value: "Create Snap Store files",
  },
  {
    key: "customDomain",
    value: "Use Custom Domain",
  },
];
