import React, { useState } from "react";
import css from "@emotion/css";
import { Row, Col, Modal } from "antd";
import PackageItem from "../molecules/PackageItem";
import { useStore, selectedApp } from "../../store";
import { updateUIState } from "../../~reusables/actions";
import { helpText } from "../../~reusables/util/i18nText";
import { track } from "../../~reusables/util/analytics";
import { professionalPlanFeatureKeys } from "../../~reusables/data/plans";
import { useAppOwnerGuard } from "../../~reusables/hooks/useAppOwnerGuard";
import { products } from "@todesktop/shared";
import { stage } from "../../~reusables/firebase/config";

const cancelPurchase = () => {
  updateUIState(undefined);
  track({ event: "Aborted Selecting Plan" });
};

export const handleChatClick = (e, message = "") => {
  e.preventDefault();
  if ("Intercom" in window) {
    return (window as any).Intercom("showNewMessage", message);
  }

  window.location.href = "mailto:hi@todesktop.com";
};

const onClickContactUs = (e) => {
  handleChatClick(e);
  return;
};

interface IChoosePackageProps {
  setPriceId: (priceId: string) => () => void;
}
const ChoosePackage: React.SFC<IChoosePackageProps> = ({ setPriceId }) => {
  const [modalVisibile, setModalVisibility] = useState(true);
  const closeModal = () => setModalVisibility(false);
  const widthWhenItemsGoInline = 950;
  const app = useStore(selectedApp);
  const { isOwner, ownerGuard } = useAppOwnerGuard();

  const proFeatures: string[] = [];
  for (const key in app) {
    const foundFeature = professionalPlanFeatureKeys.find((f) => f.key === key);
    if (app[key] && foundFeature) {
      proFeatures.push(foundFeature.value);
    }
  }

  return (
    <Modal
      visible={modalVisibile}
      footer={null}
      width={900}
      bodyStyle={{
        padding: 0,
      }}
      centered={true}
      onCancel={closeModal}
      afterClose={cancelPurchase}
      css={css`
        background: "transparent";
        border-radius: 5px;
        max-width: 100%;

        .ant-modal-content {
          background: transparent;
          box-shadow: none;
        }
        .ant-modal-close-x {
          background: #393941;
          border-radius: 100%;
          position: absolute;
          top: 0px;
          left: -24px;
          opacity: 1;
          height: 24px;
          width: 24px;
          line-height: 24px;
          font-size: 10px;
          color: #fff;
          &:hover {
            background: #000;
          }
          @media screen and (min-width: ${widthWhenItemsGoInline}px) {
            top: -30px;
            left: 0;
          }
        }
      `}
    >
      <Row>
        <Col
          sm={8}
          css={css`
            min-width: auto;
            @media screen and (max-width: ${widthWhenItemsGoInline - 1}px) {
              min-width: 100%;
              margin-bottom: 1rem;
              margin-top: 1rem;
            }
          `}
        >
          <PackageItem
            color="#757575"
            title="Essential"
            price="$58"
            shouldPinButtonToBottom={true}
            onChoose={setPriceId(
              products[stage].legacy.essentialNew.prices.monthly_58.id
            )}
            disabledTooltip={
              isOwner ? (
                ownerGuard
              ) : proFeatures.length ? (
                <>
                  You have selected the following features which are only
                  avaiable on the professional plan:
                  <ul>
                    {proFeatures.map((value) => (
                      <li key={value}>{value}</li>
                    ))}
                  </ul>
                </>
              ) : (
                ""
              )
            }
            features={[
              {
                text: "7-day free trial",
                helpText: helpText.trial,
              },
              {
                text: "Auto-update",
                helpText: helpText.update,
              },
              {
                text: "Code signing",
                helpText: helpText.sign,
              },
              {
                text: "Native installers",
                helpText: helpText.nativeInstallers,
              },
              {
                text: "Native notifications",
                helpText: helpText.nativeNotifications,
              },
              {
                text: "Windows, Mac & Linux",
                helpText: helpText.platform,
              },
            ]}
            css={css`
              height: 390px;
              position: relative;

              @media screen and (min-width: ${widthWhenItemsGoInline}px) {
                /* Vertically center the plan */
                top: 34px; /* (Height of pro plan - height of this) / 2 */
              }
            `}
          />
        </Col>
        <Col
          sm={8}
          css={css`
            min-width: auto;
            @media screen and (max-width: ${widthWhenItemsGoInline - 1}px) {
              min-width: 100%;
              margin-bottom: 1rem;
              margin-top: 1rem;
            }
          `}
        >
          <PackageItem
            color="#006DF4"
            title="Professional"
            price="$199"
            onChoose={setPriceId(
              products[stage].legacy.professional.prices.monthly_199.id
            )}
            disabledTooltip={ownerGuard}
            features={[
              {
                text: "Everything in Essential",
                helpText: helpText.everything,
              },
              { text: "7-day free trial", helpText: helpText.trial },
              {
                text: "Priority support",
                helpText: helpText.concierge,
                icon: "plus",
              },
              {
                text: "Launch at startup",
                helpText: helpText.launchAtStartup,
                icon: "plus",
              },
              {
                text: "App badge & label APIs",
                helpText: helpText.appBadgeAndLabelAPIs,
                icon: "plus",
              },
              {
                text: "Custom internal URLs",
                helpText: helpText.customInternalURLs,
                icon: "plus",
              },
              {
                text: "Custom user agent",
                helpText: helpText.customUserAgent,
                icon: "plus",
              },
              {
                text: "Launch in fullscreen mode",
                helpText: helpText.launchInFullscreen,
                icon: "plus",
              },
              {
                text: "App protocol & deeplinking",
                helpText: helpText.appProtocolAndDeeplinking,
                icon: "plus",
              },
            ]}
            isEmphasized={true}
          />
        </Col>
        <Col
          sm={8}
          css={css`
            min-width: auto;
            @media screen and (max-width: ${widthWhenItemsGoInline - 1}px) {
              min-width: 100%;
              margin-bottom: 1rem;
              margin-top: 1rem;
            }
          `}
        >
          <PackageItem
            color="#000"
            ctaText="Contact Us"
            title="Enterprise"
            pricePrefixText=""
            pricePostfixText=""
            price="Contact Us"
            onChoose={onClickContactUs}
            disabledTooltip={ownerGuard}
            features={[
              {
                text: "Everything in Professional",
                helpText: helpText.everything,
              },
              {
                text: "Dedicated account manager",
                helpText: helpText.accountManager,
                icon: "plus",
              },
              {
                text: "Custom features",
                helpText: helpText.customFeatures,
                icon: "plus",
              },
              {
                text: "Branded download links",
                helpText: helpText.cnameDownloads,
                icon: "plus",
              },
              {
                text: "In-depth analytics",
                helpText: helpText.analytics,
                icon: "plus",
              },
              {
                text: "MSI installer",
                helpText: helpText.msi,
                icon: "plus",
              },
            ]}
            shouldPinButtonToBottom={true}
            css={css`
              position: relative;
              height: 390px;

              @media screen and (min-width: ${widthWhenItemsGoInline}px) {
                /* Vertically center the plan */
                top: 34px; /* (Height of pro plan - height of this) / 2 */
              }
            `}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ChoosePackage;
