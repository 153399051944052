import pretty from "prettysize";
import downloadAnalyticsImg from "../../~reusables/images/download-analytics.png";
import {
  startOfMonth,
  endOfMonth,
  getMonth,
  format,
  startOfYear,
  endOfYear,
  addDays,
  addMonths,
  isWithinInterval,
} from "date-fns";
import { Col, message, Radio, Row, Space, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router";
import { HasFeatureGuard } from "../../components/molecules/HasFeatureGuard";
import { StyledAntdCard } from "../../components/atoms/StyledAntdCard";
import styled from "../../~reusables/contexts/ThemeContext";

import { getAmplitudeMetrics } from "../../~reusables/firebase";
import { $hasPlanAccess, selectedApp, useStore } from "../../store";
import { PlanBadge } from "../../components/molecules/PlanBadge";
import DatePicker from "../../components/atoms/DatePicker";
import {
  DLStatsRequest,
  getArtifactDownload,
  returnSortedBuilds,
} from "../../~reusables/util";
import { DonwloadCSV } from "../../components/molecules/DownlodCSV";
import { Flex, Box } from "../../components/atoms/Primitives";
import { Bar, Line } from "../../libs/chartjs";
import { proPlan } from "@todesktop/shared";

const Analytics: React.FC<RouteComponentProps> = () => {
  const app = useStore(selectedApp);

  return (
    <StyledAnalytics>
      <Row gutter={24}>
        <Col lg={12} span={24}>
          <StackChart title="Downloads" metric="downloads" />
        </Col>
        <Col lg={12} span={24}>
          <StackChart title="Releases" metric="releases" />
        </Col>
      </Row>
      {app.appType === "electron" ? (
        <Row gutter={24}>
          <Col lg={12} span={24}>
            <StackChart title="Builds Created" metric="builds" />
          </Col>
          <Col lg={12} span={24}>
            <BuildSizesChart />
          </Col>
        </Row>
      ) : null}
    </StyledAnalytics>
  );
};
interface ChartData {
  labels: string[];
  datasets: {
    data: number[];
    label: string;
    color: string;
  }[];
}

export const BuildSizesChart: React.FC = () => {
  const controls = useChartControls();
  const data = useBuildSizes(controls.view, controls.date);

  return (
    <StyledAntdCard title="Build Sizes" extra={<ChartControls {...controls} />}>
      <Line
        options={{
          responsive: true,
          plugins: {
            legend: {
              display: data.datasets.length > 1 ? true : false,
            },
          },
          scales: {
            y: {
              min: 0,
              ticks: {
                precision: 0,
                callback: (value) => pretty(value),
              },
            },
          },
        }}
        data={{
          labels: data.labels,
          datasets: data.datasets.map(({ data, label, color }) => ({
            label,
            data,
            borderColor: color,
            backgroundColor: color,
          })),
        }}
      />
      <Flex pt={5} justifyContent="flex-end">
        <DonwloadCSV data={data} name="build-sizes" />
      </Flex>
    </StyledAntdCard>
  );
};

const useBuildSizes = (view: "month" | "year", date: Date): ChartData => {
  const DATE_FORMAT = view === "month" ? YEAR_MONTH_DAY : YEAR_MONTH;
  const period = getPeriod(view, date);

  const mappedBuilds = useStore((state) => state.buildState.builds);
  const builds = returnSortedBuilds(mappedBuilds).filter((build) =>
    isWithinInterval(new Date(build.createdAt), period)
  );

  const hasAccess = useStore($hasPlanAccess(proPlan));
  if (!hasAccess) return mockInitialData(view, date);

  const groupedStats: Map<
    string,
    { color: string; dates: Map<string, { count: number; sum: number }> }
  > = new Map();

  builds.forEach((build) => {
    const requests = [
      // mac artifacts are colored with shades of blue from https://ant.design/docs/spec/colors
      generateRequest("x64", "dmg", "mac", "Mac x64 DMG", "#4096ff"),
      generateRequest("arm64", "dmg", "mac", "Mac arm64 DMG", "#69b1ff"),
      generateRequest("x64", "zip", "mac", "Mac x64 ZIP", "#91caff"),
      generateRequest("arm64", "zip", "mac", "Mac arm64 ZIP", "#bae0ff"),
      // windows have shades of cyan
      generateRequest("x64", "nsis", "windows", "Windows NSIS", "#36cfc9"),
      generateRequest(
        "x64",
        "nsis-web",
        "windows",
        "Windows NSIS Web",
        "#5cdbd3"
      ),
      generateRequest("x64", "msi", "windows", "Windows MSI", "#87e8de"),
      generateRequest("x64", "appx", "windows", "Windows AppX", "#b5f5ec"),
      // linux has shades of green
      generateRequest("x64", "appImage", "linux", "Linux AppImage", "#73d13d"),
      generateRequest("x64", "deb", "linux", "Linux Debian", "#95de64"),
      generateRequest("x64", "rpm", "linux", "Linux RPM", "#b7eb8f"),
      generateRequest("x64", "snap", "linux", "Linux Snap", "#d9f7be"),
    ];

    const dateStr = format(new Date(build.createdAt), DATE_FORMAT);
    requests.forEach(({ arch, artifactName, platform, label, color }) => {
      const artifactDownload = getArtifactDownload(build, {
        arch,
        artifactName,
        platform,
      });
      if (!artifactDownload?.size) return;

      const stats = groupedStats.get(label) || { dates: new Map(), color };
      const selected = stats.dates.get(dateStr) || { count: 0, sum: 0 };

      stats.dates.set(dateStr, {
        count: selected.count + 1,
        sum: selected.sum + artifactDownload.size || 0,
      });
      groupedStats.set(label, stats);
    });
  });

  const getLabels = view === "month" ? getDaysInMonth : getMonthsInYear;
  const labels = getLabels(date, DATE_FORMAT);

  const datasets: ChartData["datasets"] = [...groupedStats.entries()].map(
    ([artifact, { color, dates }]) => {
      const data = labels.map((dateStr) => {
        const dateStats = dates.get(dateStr);
        if (!dateStats) return null;

        const avgSizeForGivenDate = dateStats.sum / dateStats.count;
        return Math.round(avgSizeForGivenDate);
      });

      return { color, label: artifact, data };
    }
  );

  return { datasets, labels };
};

const generateRequest = (
  arch: DLStatsRequest["arch"],
  artifactName: DLStatsRequest["artifactName"],
  platform: DLStatsRequest["platform"],
  label: string,
  color: string
): DLStatsRequest & { label: string; color: string } => ({
  arch,
  artifactName,
  platform,
  label,
  color,
});

interface StackChartConfig {
  metric: "downloads" | "builds" | "releases";
  title: string;
}

export const StackChart: React.FC<StackChartConfig> = ({ metric, title }) => {
  const hasAccess = useStore($hasPlanAccess(proPlan));

  const app = useStore(selectedApp);
  const controls = useChartControls();
  const { view, date, cache } = controls;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ChartData>(() =>
    mockInitialData(view, date)
  );

  useEffect(() => {
    if (hasAccess) {
      const { start, end } = getPeriod(view, date);
      const period = {
        start: format(start, AMPLITUDE_FORMAT),
        end: format(end, AMPLITUDE_FORMAT),
      };

      const cacheKey = `${period.start}:${period.end}`;
      const cachedValue = cache.current.get(cacheKey);
      if (cachedValue) {
        setData(cachedValue);
        return;
      }

      setLoading(true);
      getAmplitudeMetrics({ appId: app.id, metric, period })
        .then(({ data }) => {
          const result = view === "month" ? data : groupDataByMonth(date, data);
          cache.current.set(cacheKey, result);
          setData(result);
        })
        .catch((err) => message.error(err.message))
        .finally(() => setLoading(false));
    }
  }, [hasAccess, cache, view, date, metric, app.id]);

  return (
    <StyledAntdCard title={title} extra={<ChartControls {...controls} />}>
      <Spin tip="Loading" spinning={loading}>
        <Bar
          options={{
            responsive: true,
            plugins: {
              legend: {
                display: data.datasets.length > 1 ? true : false,
              },
            },
            scales: {
              x: {
                stacked: true,
              },
              y: {
                stacked: true,
                min: 0,
                ticks: { precision: 0 },
              },
            },
          }}
          data={{
            labels: data.labels,
            datasets: data.datasets.map(({ data, label, color }) => ({
              label,
              data,
              backgroundColor: color,
            })),
          }}
        />
        <Flex pt={5} justifyContent="flex-end">
          <DonwloadCSV data={data} name={metric} />
        </Flex>
      </Spin>
    </StyledAntdCard>
  );
};

interface ChartControlsProps {
  cache: React.MutableRefObject<Map<string, ChartData>>;
  view: "month" | "year";
  setView: React.Dispatch<React.SetStateAction<"month" | "year">>;
  date: Date;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
}

const ChartControls: React.FC<ChartControlsProps> = (controls) => {
  const { date, setDate, setView, view } = controls;
  const hasPlanAccess = useStore($hasPlanAccess(proPlan));

  return (
    <HasFeatureGuard
      src={downloadAnalyticsImg}
      alt="Mac, Windows and Linux graphic showing the configurable build artifacts"
      name="Analytics"
      description="Track monthly customer downloads and application releases"
      plan={proPlan}
    >
      <Space>
        <PlanBadge plan={proPlan} showLabel={false} />
        <DatePicker
          disabled={!hasPlanAccess}
          value={date}
          onChange={(date) => setDate(date)}
          picker={view}
          renderExtraFooter={() => {
            return (
              <Box pt={5}>
                <Radio.Group
                  options={[
                    { label: "Month View", value: "month" },
                    { label: "Year View", value: "year" },
                  ]}
                  onChange={(e) => setView(e.target.value)}
                  value={view}
                  optionType="button"
                />
              </Box>
            );
          }}
        />
      </Space>
    </HasFeatureGuard>
  );
};

const useChartControls = (): ChartControlsProps => {
  const cache = useRef(new Map<string, ChartData>());
  const [view, setView] = useState<"month" | "year">("month");
  const [date, setDate] = useState(new Date());

  return { cache, view, setView, date, setDate };
};

function getPeriod(view: "month" | "year", date: Date) {
  switch (view) {
    case "month":
      return { start: startOfMonth(date), end: endOfMonth(date) };
    case "year":
      return { start: startOfYear(date), end: endOfYear(date) };
  }
}

const groupDataByMonth = (date: Date, data: ChartData): ChartData => {
  const datasets = data.datasets.map((dataset) => {
    const newData: number[] = new Array(12).fill(0);
    dataset.data.forEach((value, labelIndex) => {
      const label = data.labels[labelIndex];
      const month = getMonth(new Date(label));
      newData[month] = newData[month] + value;
    });

    return { ...dataset, data: newData };
  });

  const start = startOfYear(date);
  const labels = new Array(12)
    .fill(0)
    .map((_, i) => format(addMonths(start, i), YEAR_MONTH));

  return { datasets, labels };
};

function mockInitialData(view: "month" | "year", date: Date) {
  switch (view) {
    case "month":
      const daysInMonth = getDaysInMonth(date);
      return {
        datasets: [
          {
            data: daysInMonth.map(() => 0),
            label: "Other",
            color: "#8c8c8c",
          },
        ],
        labels: daysInMonth,
      };
    case "year":
      const monthsInYear = getMonthsInYear(date);
      return {
        datasets: [
          {
            data: monthsInYear.map(() => 0),
            label: "Other",
            color: "#8c8c8c",
          },
        ],
        labels: monthsInYear,
      };
  }
}

const getMonthsInYear = (date: Date, dateFormat = YEAR_MONTH) =>
  getDateLabels(date, {
    getStart: startOfYear,
    getStop: endOfYear,
    increment: addMonths,
    format: (date) => format(date, dateFormat),
  });

const getDaysInMonth = (date: Date, dateFormat = YEAR_MONTH_DAY) =>
  getDateLabels(date, {
    getStart: startOfMonth,
    getStop: endOfMonth,
    increment: addDays,
    format: (date) => format(date, dateFormat),
  });

function getDateLabels(
  date: Date,
  actions: {
    format: (date: Date) => string;
    getStart: (date: Date) => Date;
    getStop: (date: Date) => Date;
    increment: (date: Date, num: number) => Date;
  }
) {
  const dateArray: string[] = [];
  const start = actions.getStart(date);
  const stop = actions.getStop(date);

  let currentDate = start;
  while (currentDate <= stop) {
    dateArray.push(actions.format(currentDate));
    currentDate = actions.increment(currentDate, 1);
  }

  return dateArray;
}

const YEAR_MONTH = "yyyy-MM";
const YEAR_MONTH_DAY = "yyyy-MM-dd";
const AMPLITUDE_FORMAT = "yyyyMMdd";

const StyledAnalytics = styled.main``;

export default Analytics;
