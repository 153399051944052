// libs
import { Switch, Tooltip, Modal, SwitchProps } from "antd";
import React from "react";
import css from "@emotion/css";
import {
  CheckOutlined,
  CloseOutlined,
  WarningOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

// components
import { StyledAntdCard } from "../atoms/StyledAntdCard";
import { Space } from "../atoms/Space";
import { Flex, Box } from "../atoms/Primitives";
import { Heading } from "../atoms/Heading";
import { PlanBadge } from "./PlanBadge";
import { LabelledItem } from "../atoms/LabelledItem";
import { HasFeatureGuard } from "./HasFeatureGuard";

// utils
import { updateFirestoreApp } from "../../~reusables/actions";
import { callFirebaseFunction } from "../../~reusables/firebase/functions";
import { track } from "../../~reusables/util/analytics";
import { useTheme } from "../../~reusables/contexts/ThemeContext";
import { selectedApp, useStore } from "../../store";
import buildArtifactsFeatureImg from "../../~reusables/images/buildArtifactsFeature.svg";
import { IApp, IUser, Plan, legacyProPlan, proPlan } from "@todesktop/shared";
import { SnapConfig } from "./ArtifactsConfig/SnapConfig";
import { AppxConfig } from "./ArtifactsConfig/AppxConfig";
import { NSISConfig } from "./ArtifactsConfig/NSISConfig";
import { isTDBuilderApp } from "../../~reusables/util/schemaChecks";
import { Text } from "../atoms/Text";
import { Link } from "react-router-dom";

const deleteAppArtifactSettingsCache = callFirebaseFunction(
  "deleteAppArtifactSettingsCache"
);

const toggleArtifact = (
  field: keyof Pick<
    IApp,
    | "shouldCreate32BitWindowsArtifacts"
    | "shouldCreateArm64LinuxArtifacts"
    | "shouldCreateArm64WindowsArtifacts"
    | "shouldCreateAppImages"
    | "shouldCreateAppleSiliconAssets"
    | "shouldCreateAppleIntelArtifacts"
    | "shouldCreateAppXFiles"
    | "shouldCreateDebianPackages"
    | "shouldCreateDMGs"
    | "shouldCreateMacAppStoreFiles"
    | "shouldCreateMacPKG"
    | "shouldCreateMacUniversalInstaller"
    | "shouldCreateMacZipInstallers"
    | "shouldCreateMSIInstallers"
    | "shouldCreateNSISInstallers"
    | "shouldCreateNSISWebInstaller"
    | "shouldCreateRPMPackages"
    | "shouldCreateSnapFiles"
  >,
  id: IApp["id"],
  user: IUser
) => async (checked: boolean) => {
  track({
    event: "Toggle Build Artifact",
    properties: { artifact: field, checked },
  });

  updateFirestoreApp({ [field]: checked });

  // If it's not a temporary app, clear cache for downloads
  if (user && user.id) {
    // We don't need to wait for this to finish
    deleteAppArtifactSettingsCache({ id });
  }
};

export const ArtifactsModal: React.FC<{
  showModal: boolean;
  setShowModal: (value: React.SetStateAction<boolean>) => void;
}> = ({ showModal, setShowModal }) => {
  return (
    <Modal
      centered
      footer={null}
      visible={showModal}
      onCancel={() => setShowModal(false)}
      closable
    >
      <Heading textAlign="center" pb={5} variant="h3" as="h3">
        Configure your build artifacts
      </Heading>
      <Artifacts />
    </Modal>
  );
};

export const ArtifactsCard: React.FC = () => {
  return (
    <StyledAntdCard
      title={
        <Space direction="column" align="flex-start">
          <Flex alignItems="center">
            <Heading variant="h4" as="h4" mr={5}>
              Artifacts
            </Heading>
            <PlanBadge
              plan={legacyProPlan}
              tooltip="Enabling additional artifacts are only available on the professional plan."
            />
          </Flex>
          <Text color="support">
            Toggle on or off the different application artifacts that you would
            like to build for.
          </Text>
        </Space>
      }
    >
      <Artifacts />
    </StyledAntdCard>
  );
};

const artifactLabels = {
  appImage: "Linux AppImage",
  debian: "Linux Debian",
  dmg: "Mac DMG",
  linuxArm64: "Linux ARM64",
  macIntelX64: "Mac Intel Assets",
  macArm64: "Mac Apple Silicon Assets",
  mas: "Mac App Store",
  msi: "Windows MSI",
  nsis: "Windows NSIS",
  ["nsis-web"]: "Windows NSIS Web",
  pkg: "Mac PKG",
  rpm: "Linux RPM",
  snap: "Linux Snap Store",
  universalMac: "Mac Universal Installer",
  was: "Windows App Store",
  windowsArm64: "Windows ARM64",
  zip: "Mac Zip",
};

export const Artifacts: React.FC = () => {
  const { space } = useTheme();
  const app = useStore(selectedApp);
  const {
    appType,
    id,
    shouldCreateAppImages,
    shouldCreateAppleSiliconAssets,
    shouldCreateAppleIntelArtifacts,
    shouldCreateAppXFiles,
    shouldCreateArm64LinuxArtifacts,
    shouldCreateArm64WindowsArtifacts,
    shouldCreateDebianPackages,
    shouldCreateDMGs,
    shouldCreateMacAppStoreFiles,
    shouldCreateMacPKG,
    shouldCreateMacUniversalInstaller,
    shouldCreateMacZipInstallers,
    shouldCreateMSIInstallers,
    shouldCreateRPMPackages,
    shouldCreateSnapFiles,
    shouldCreateNSISInstallers,
    shouldCreateNSISWebInstaller,
  } = app;
  const user = useStore((state) => state.user);

  const masArtifactConfig: ArtifactConfig = {
    label: artifactLabels.mas,
    tooltip: "Creates a file for manual distribution to the Mac App Store",
    checkedTooltip: {
      type: "info",
      message: (
        <>
          Requires{" "}
          <Link
            to={`/apps/${app.id}/settings?uistate=settings-certificates`}
            style={{ color: "white", textDecoration: "underline" }}
          >
            Mac App Store certificates
          </Link>{" "}
          and doesn't support auto-updates
        </>
      ),
    },
    checked: shouldCreateMacAppStoreFiles,
    plan: legacyProPlan,
    onChange: (checked) => {
      if (checked) {
        const artifactsToDisable = [
          { key: "shouldCreateDMGs", label: artifactLabels.dmg },
          {
            key: "shouldCreateMacZipInstallers",
            label: artifactLabels.zip,
          },
          {
            key: "shouldCreateMacUniversalInstaller",
            label: artifactLabels.universalMac,
          },
          { key: "shouldCreateMacPKG", label: artifactLabels.pkg },
        ] as const;

        const filtered = artifactsToDisable.filter(
          (artifact) => app[artifact.key]
        );

        if (filtered.length) {
          Modal.error({
            title: `Enabling Mac App Store requires disabling other Mac artifacts`,
            content: `Please disable the following: ${filtered
              .map((item) => item.label)
              .join(", ")}.`,
          });
          return;
        }
      }

      return toggleArtifact("shouldCreateMacAppStoreFiles", id, user)(checked);
    },
  };

  const maybeDisableUniversalInstaller = (isEnabled: boolean) => {
    if (
      !isEnabled &&
      shouldCreateMacUniversalInstaller &&
      appType === "electron"
    ) {
      // If we're disabling this, we need to disable the universal installer
      toggleArtifact("shouldCreateMacUniversalInstaller", id, user)(false);
    }
  };

  let artifacts: ArtifactConfig[] = [
    appType === "electron"
      ? {
          label: artifactLabels.macIntelX64,
          tooltip: "Build Apple Intel versions of Mac Assets.",
          disabled: !shouldCreateAppleSiliconAssets,
          checked: shouldCreateAppleIntelArtifacts !== false,
          onChange: (isEnabled) => {
            maybeDisableUniversalInstaller(isEnabled);
            toggleArtifact(
              "shouldCreateAppleIntelArtifacts",
              id,
              user
            )(isEnabled);
          },
        }
      : null,
    appType === "electron"
      ? {
          label: artifactLabels.macArm64,
          tooltip: "Build Apple Silicon versions of Mac Assets.",
          disabled: shouldCreateAppleIntelArtifacts === false,
          checked: shouldCreateAppleSiliconAssets,
          plan: legacyProPlan,
          onChange: (isEnabled) => {
            maybeDisableUniversalInstaller(isEnabled);
            toggleArtifact(
              "shouldCreateAppleSiliconAssets",
              id,
              user
            )(isEnabled);
          },
        }
      : null,
    {
      label: artifactLabels.dmg,
      tooltip: "DMG is enabled by default",
      checked: shouldCreateDMGs,
      onChange: toggleArtifact("shouldCreateDMGs", id, user),
      disabled: app.shouldCreateMacAppStoreFiles,
    },
    {
      label: artifactLabels.zip,
      tooltip: "Zip installers",
      checked: shouldCreateMacZipInstallers,
      onChange: toggleArtifact("shouldCreateMacZipInstallers", id, user),
      disabled: app.shouldCreateMacAppStoreFiles,
    },
    {
      label: artifactLabels.universalMac,
      tooltip:
        "A lightweight universal binary for Mac that will download the correct Intel or Apple Silicon version of your app and install it to the Applications folder.",
      checked: shouldCreateMacUniversalInstaller,
      disabled: app.shouldCreateMacAppStoreFiles,
      onChange: (isEnabled) => {
        if (
          isEnabled &&
          !shouldCreateAppleSiliconAssets &&
          appType === "electron"
        ) {
          Modal.error({
            title: `Please enable ${artifactLabels.macArm64}`,
            content: `You must enable ${artifactLabels.macArm64} assets to enable ${artifactLabels.universalMac}.`,
          });
          return;
        }
        return toggleArtifact(
          "shouldCreateMacUniversalInstaller",
          id,
          user
        )(isEnabled);
      },
    },
    ...(user?.featureFlags?.macAppStore ? [masArtifactConfig] : []),
    {
      label: artifactLabels.pkg,
      disabled: app.shouldCreateMacAppStoreFiles,
      checkedTooltip: {
        type: "info",
        message: (
          <>
            Requires a{" "}
            <Link
              to={`/apps/${app.id}/settings?uistate=settings-certificates`}
              style={{ color: "white", textDecoration: "underline" }}
            >
              Developer ID Installer certificate
            </Link>
          </>
        ),
      },
      tooltip: "Mac PKG installer",
      checked: shouldCreateMacPKG,
      onChange: toggleArtifact("shouldCreateMacPKG", id, user),
      plan: legacyProPlan,
    },
    {
      label: artifactLabels.was,
      tooltip:
        "Creates an AppX file for manual distribution to the Windows App Store",
      checkedTooltip: {
        type: "warning",
        message: "Auto-updates are disabled for this artifact",
      },
      checked: shouldCreateAppXFiles,
      onChange: toggleArtifact("shouldCreateAppXFiles", id, user),
      ConfigComponent: AppxConfig,
      plan: legacyProPlan,
    },
    {
      label: artifactLabels.nsis,
      tooltip: "NSIS is enabled by default",
      checked: shouldCreateNSISInstallers,
      onChange: toggleArtifact("shouldCreateNSISInstallers", id, user),
      ConfigComponent: NSISConfig,
    },
    {
      label: artifactLabels["nsis-web"],
      tooltip: "Creates a lightweight NSIS Web installer",
      checked: shouldCreateNSISWebInstaller,
      onChange: toggleArtifact("shouldCreateNSISWebInstaller", id, user),
      plan: proPlan,
    },
    {
      label: artifactLabels.windowsArm64,
      tooltip: "Create ARM64 NSIS build",
      checked: shouldCreateArm64WindowsArtifacts,
      onChange: (isEnabled) => {
        if (
          isEnabled &&
          !shouldCreateNSISInstallers &&
          !shouldCreateNSISWebInstaller
        ) {
          Modal.error({
            title: `Please enable ${artifactLabels.nsis} or ${artifactLabels["nsis-web"]}`,
            content: `You must enable ${artifactLabels.nsis} or ${artifactLabels["nsis-web"]} assets to enable ${artifactLabels.windowsArm64}.`,
          });
          return;
        }
        return toggleArtifact(
          "shouldCreateArm64WindowsArtifacts",
          id,
          user
        )(isEnabled);
      },
    },
    {
      checkedTooltip:
        app.appType !== "electron"
          ? {
              type: "warning",
              message: "Auto-updates are disabled for this artifact",
            }
          : undefined,
      label: artifactLabels.msi,
      tooltip: "MSI Installers",
      checked: shouldCreateMSIInstallers,
      onChange: toggleArtifact("shouldCreateMSIInstallers", id, user),
      plan: legacyProPlan,
    },
  ];

  if (appType !== "menubar" || isTDBuilderApp(app)) {
    artifacts.push(
      {
        label: artifactLabels.linuxArm64,
        tooltip: "Create ARM64 AppImage and DEB packages",
        checked: shouldCreateArm64LinuxArtifacts,
        onChange: toggleArtifact("shouldCreateArm64LinuxArtifacts", id, user),
      },
      {
        label: artifactLabels.appImage,
        tooltip: "AppImage is enabled by default",
        checked: shouldCreateAppImages,
        onChange: toggleArtifact("shouldCreateAppImages", id, user),
      },
      {
        label: artifactLabels.debian,
        tooltip: "Debian Packages",
        checked: shouldCreateDebianPackages,
        onChange: toggleArtifact("shouldCreateDebianPackages", id, user),
        plan: legacyProPlan,
      },
      {
        label: artifactLabels.rpm,
        tooltip: "RPM Packages",
        checked: shouldCreateRPMPackages,
        onChange: toggleArtifact("shouldCreateRPMPackages", id, user),
        plan: legacyProPlan,
      },
      {
        label: artifactLabels.snap,
        tooltip: "Snap file",
        checkedTooltip: {
          type: "warning",
          message: "Auto-updates are disabled for this artifact",
        },
        checked: shouldCreateSnapFiles,
        onChange: toggleArtifact("shouldCreateSnapFiles", id, user),
        ConfigComponent: SnapConfig,
        plan: legacyProPlan,
      }
    );
  }

  // Remove artifacts that are null
  artifacts = artifacts.filter((x) => !!x);

  const isOneOrLessArtifactActive =
    artifacts.filter(({ checked }) => checked).length <= 1;

  return (
    <>
      {artifacts.map(
        (
          {
            label,
            tooltip,
            checkedTooltip,
            plan,
            ConfigComponent,
            ...switchProps
          },
          idx
        ) => {
          const isDisabled = switchProps.checked && isOneOrLessArtifactActive;
          // const isEssential = [
          //   artifactLabels.dmg,
          //   artifactLabels.zip,
          //   artifactLabels.nsis,
          //   artifactLabels.appImage,
          //   artifactLabels.universalMac,
          // ].includes(label);

          const icons = {
            warning: (
              <WarningOutlined style={{ color: "#fa8c16", fontSize: 20 }} />
            ),
            info: (
              <InfoCircleOutlined style={{ color: "#4096ff", fontSize: 20 }} />
            ),
          };

          const artifactSwitch = (
            <Flex css={{ alignItems: "center", gap: "8px" }}>
              <Tooltip
                title={
                  isDisabled
                    ? "There must be at least one active artifact"
                    : tooltip
                }
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  disabled={isDisabled}
                  {...switchProps}
                />
              </Tooltip>
              {switchProps.checked && checkedTooltip && (
                <Tooltip title={checkedTooltip.message}>
                  {icons[checkedTooltip.type]}
                </Tooltip>
                // <Tooltip title="Auto-updates are disabled for this artifact">
                //   {icons[checkedTooltip.type]}
                // </Tooltip>
              )}
            </Flex>
          );

          return (
            <LabelledItem
              key={label}
              altBg={idx % 2 !== 0 ? true : false}
              padding={`${space[6]}px`}
              label={
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 8px;
                  `}
                >
                  <Text fontWeight={600}>{label}</Text>
                  {plan && <PlanBadge plan={plan} showLabel={false} />}
                </div>
              }
            >
              {plan ? (
                <HasFeatureGuard
                  src={buildArtifactsFeatureImg}
                  alt="Mac, Windows and Linux graphic showing the configurable build artifacts"
                  name="Build artifacts"
                  description="Distribute your application via Mac ZIP, Windows MSI, Linux Debian and more. "
                  plan={plan}
                >
                  {artifactSwitch}
                </HasFeatureGuard>
              ) : (
                artifactSwitch
              )}

              {ConfigComponent && switchProps.checked ? (
                <Box pt={4}>
                  <ConfigComponent />
                </Box>
              ) : (
                ""
              )}
            </LabelledItem>
          );
        }
      )}
    </>
  );
};

interface ArtifactConfig extends SwitchProps {
  label: string;
  tooltip: string;
  checkedTooltip?: { type: "warning" | "info"; message: React.ReactNode };
  ConfigComponent?: React.FC;
  plan?: Plan;
}
