import { message } from "antd";
import { useRef, useEffect } from "react";
import pretty from "prettysize";
import {
  Arch,
  Build,
  IApp,
  isPlatformBuildRunning,
  LinuxArtifactName,
  MacArch,
  MacArtifactName,
  PlatformName,
  WindowsArtifactName,
} from "@todesktop/shared";
import type { DownloadLinkPlatformTypes } from "../types";

const globalAny: any = global;

// TODO - necessary for backwards compatibility. Dates were passed to
// 'createdAt' in an inappropriate manner previously and this fixes that
export const parseDate = (date: string) => {
  let parsedDate = Date.parse(date);
  if (isNaN(parsedDate)) {
    const match = date.match(/\[(.*?)\]/);
    if (match) parsedDate = parseInt(match[1]);
  }
  return parsedDate;
};

export const returnSortedBuilds = (
  builds: { [key: string]: Build },
  omitBuildIds: string[] = []
) => {
  return Object.values(builds)
    .filter((build) => !omitBuildIds.includes(build.id))
    .sort((a, b) => {
      const date1 = parseDate(b.createdAt);
      const date2 = parseDate(a.createdAt);

      return date1 - date2;
    });
};

export const findReleasedBuildId = (builds: Build[]) => {
  const sortedReleasedBuilds = builds
    .filter((build) => build.releasedAt)
    .sort((a, b) => {
      const date1 = parseDate(b.releasedAt);
      const date2 = parseDate(a.releasedAt);

      return date1 - date2;
    });

  if (sortedReleasedBuilds.length) {
    return sortedReleasedBuilds[0].id;
  } else {
    return "";
  }
};

export type DLStatsRequest = {
  arch: Arch | MacArch;
  artifactName: LinuxArtifactName | MacArtifactName | WindowsArtifactName;
  platform: DownloadLinkPlatformTypes;
  release?: Build;
};

export type DownloadLinkStats =
  | {
      direction: string;
      percentChange: string;
      release: Build;
      size: any;
      statusColor: string;
      mb: number;
    }
  | Partial<{
      direction: string;
      percentChange: string;
      release: Build;
      size: any;
      statusColor: string;
      mb: number;
    }>;

export const getDownloadLinkStats = (
  build: Build,
  release: Build | null,
  options: DLStatsRequest
): DownloadLinkStats => {
  const buildArtifact = getArtifactDownload(build, options);
  const releaseArtifact = getArtifactDownload(release, options);

  if (!build || !buildArtifact?.size) {
    return {};
  }

  let percent: number | null = null;
  if (releaseArtifact?.size) {
    const releaseSize = releaseArtifact.size;
    const decreaseValue = buildArtifact.size - releaseSize;

    percent = parseFloat(((decreaseValue / releaseSize) * 100).toFixed(2));
  }

  const sign = Math.sign(percent);
  const percentChange = sign > 0 ? `+${percent}%` : `${percent}%`;

  return {
    direction: sign > 0 ? "increase" : sign < 0 ? "decrease" : "change",
    percentChange: percent ? percentChange : null,
    release: release ? release : null,
    size: pretty(buildArtifact.size),
    mb: buildArtifact.size / (1024 * 1024),
    statusColor: percent > 1 ? "red" : "green",
  };
};

export const getPriorRelease = (
  build: Build,
  builds: { [key: string]: Build }
): Build | null => {
  if (!build) return null;

  return (
    Object.values(builds)
      // filter for releases before the current build's startedAt
      .filter(
        ({ releasedAt, id }) =>
          releasedAt &&
          id !== build.id &&
          Date.parse(build.startedAt) > Date.parse(releasedAt)
      )
      // find nearest release by bubbling closest releases to the top
      .sort((a, b) => Date.parse(b.releasedAt) - Date.parse(a.releasedAt))
      .find(Boolean)
  );
};

export const getPercentChange = (currentSize = 0, previousSize = 0) => {
  if (previousSize === 0) {
    return {
      direction: "change",
      percentChange: null,
      size: pretty(currentSize),
      statusColor: "green",
    };
  }

  const decreaseValue = currentSize - previousSize;
  const percent = parseFloat(((decreaseValue / previousSize) * 100).toFixed(2));

  const sign = Math.sign(percent);
  const percentChange = sign > 0 ? `+${percent}%` : `${percent}%`;

  return {
    direction: sign > 0 ? "increase" : sign < 0 ? "decrease" : "change",
    percentChange: percent ? percentChange : null,
    size: pretty(currentSize),
    statusColor: percent > 1 ? "red" : "green",
  };
};

export const getSortedReleasedBuilds = (builds: Record<string, Build>) => {
  return Object.values(builds)
    .filter(({ releasedAt }) => releasedAt)
    .sort((a, b) => Date.parse(b.releasedAt) - Date.parse(a.releasedAt));
};

export const getNumberOfLaterBuilds = (
  referenceBuild: Build,
  builds: { [key: string]: Build }
) => {
  const sortedBuilds = returnSortedBuilds(builds);
  return sortedBuilds.findIndex((build) => build.id === referenceBuild.id);
};

export const getArtifactDownload = (
  build: Build,
  { arch, artifactName, platform }: DLStatsRequest
) => {
  return build?.[platform]?.artifactDownloads?.[artifactName]?.[arch] || null;
};

export const omit = (ex: string) => (prop: string) => prop !== ex;

export function usePrevious(value) {
  const ref = useRef(undefined);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const submitOnNavigate = (self: {
  isFormValid: boolean;
  submitForm?: () => void;
  validateForm?: () => void;
  props: any;
}) => {
  globalAny.forceUnblock = self.props.history.block(() => {
    if (self.validateForm) {
      self.validateForm();
    }

    if (self.isFormValid) {
      if (self.submitForm) {
        self.submitForm();
      }
      return true;
    }
    message.error(
      "Please fix errors on this page before navigating to a new page"
    );
    return false;
  });
  return globalAny.forceUnblock;
};

// exclude settings that just modify the build architectures e.g. shouldCreateAppleSiliconAssets, shouldCreate32BitWindowsArtifacts etc.
export const getPlatformArtifactSettingsMap = (appData: IApp) => ({
  linux:
    appData.appType === "menubar"
      ? [] // if menubar app, no need to check linux config
      : [
          appData.shouldCreateAppImages,
          appData.shouldCreateDebianPackages,
          appData.shouldCreateRPMPackages,
          appData.shouldCreateSnapFiles,
        ],
  mac: [
    appData.shouldCreateDMGs,
    appData.shouldCreateMacAppStoreFiles,
    appData.shouldCreateMacPKG,
    appData.shouldCreateMacZipInstallers,
    appData.shouldCreateMacUniversalInstaller,
  ],
  windows: [
    appData.shouldCreateMSIInstallers,
    appData.shouldCreateNSISInstallers,
    appData.shouldCreateNSISWebInstaller,
    appData.shouldCreateAppXFiles,
  ],
});

export const codeSignSkipReasonMap = {
  "no-cert": "No code-signing certificate was set up for this platform",
  "user-disabled": "Code signing was disabled via --code-sign=false",
};

export const getCodeSignSkipExplanation = (
  codeSignSkipReason:
    | Build["mac"]["codeSignSkipReason"]
    | Build["windows"]["codeSignSkipReason"]
    | Build["linux"]["codeSignSkipReason"]
) =>
  codeSignSkipReasonMap[codeSignSkipReason] || "This build was not code signed";

export const getPlatformBuildsNotCodeSigned = (build: Build) => {
  /**
   * Note that we're purposely leaving `build.linux` out of the filter array
   * because the code sign status of Linux doesn't matter for release
   */
  return Object.entries({
    [PlatformName.mac]: build["mac"],
    [PlatformName.windows]: build["windows"],
  }).filter(
    ([_, platformBuild]) =>
      !isPlatformBuildRunning(platformBuild) &&
      !platformBuild.shouldSkip &&
      !platformBuild.didCodeSign
  );
};
