import css from "@emotion/css";
import { Alert, Button, Modal } from "antd";
import React, { useRef, useState } from "react";
import { isAppOwner, useStore } from "../../store";
import { track } from "../../~reusables/util/analytics";
import AppIcon from "../atoms/AppIcon";
import Spinner from "../atoms/Spinner";

import { IApp, products } from "@todesktop/shared";
import { message } from "antd";
import { store } from "../../store";
import {
  createCustomerCheckoutSession,
  deleteApp,
  removeUIState,
} from "../../~reusables/actions";
import PayButton from "../account/PayButton";
import { stage } from "../../~reusables/firebase/config";

type Props = {
  app: IApp;
  priceId: string;
};

export default function PayModal({ app, priceId }: Props) {
  const user = useStore((state) => state.user);
  const owner = useStore((state) => state.appToUser[app.id]);

  const [loading, setLoading] = useState(false);
  const isOwner = useStore(isAppOwner);

  let stripeCustomerId: null | string = null;
  if (user) {
    stripeCustomerId = user.stripeCustomerId;
  }
  const existingCustomer = useRef(!!stripeCustomerId);
  const [isModalVisible, setModalVisibility] = useState(true);

  let requestCloseModal;
  const requestCloseModalFn = (fn) => {
    requestCloseModal = fn;
  };

  const closeModal = () => {
    if (typeof requestCloseModal === "function") {
      if (requestCloseModal()) {
        setModalVisibility(false);
      }
      return;
    }
    setModalVisibility(false);
  };

  const onCloseModal = () => {
    if (priceId === "cli_founder") {
      Modal.confirm({
        title: "Closing this modal will redirect you back home",
        onOk() {
          closeModal();
        },
      });
    } else {
      closeModal();
    }
  };

  const { title, afterClose, payButtonText, PriceAlert } = getPayModalsInfo(
    priceId
  );

  const cancelPurchase = () => {
    afterClose();

    track({
      event: "Aborted Beginning Trial",
      properties: {
        plan: priceId,
        existingCustomer: existingCustomer.current,
      },
    });
  };

  async function handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    setLoading(true);

    // Redirect to Stripe checkout
    await createCustomerCheckoutSession({
      successUrl: window.location.href,
      cancelUrl: window.location.href,
      priceId,
      appId: app.id,
    });
  }

  return (
    <Modal
      visible={isModalVisible}
      footer={null}
      closable={true}
      centered={true}
      onCancel={onCloseModal}
      afterClose={cancelPurchase}
      css={css`
        overflow: hidden;
      `}
    >
      {user && isOwner ? (
        <div
          css={css`
            margin: 0 auto;
          `}
        >
          <AppIcon iconUrl={app.icon} />
          <h2
            css={css`
              font-size: 32px;
              text-align: center;
              font-weight: normal;
            `}
          >
            {title}
          </h2>

          <Alert showIcon={true} message={PriceAlert} type="info" />
          <form onSubmit={handleSubmit}>
            <>
              <PayButton
                css={css`
                  margin-top: 25px;
                `}
                type="submit"
                loading={loading}
              >
                {payButtonText || "Begin Free Trial"}
              </PayButton>
            </>
          </form>

          {app.appType === "electron" ? (
            <Button
              onClick={() => {
                Modal.confirm({
                  title: "Are you sure you want to delete this app?",
                  content: "This action can not be undone.",
                  onOk() {
                    deleteApp(user, app);
                    removeUIState();
                  },
                  okText: "Yes, Delete",
                  okType: "danger",
                  cancelText: "No",
                });
              }}
            >
              Delete app
            </Button>
          ) : null}
        </div>
      ) : user && owner ? (
        <div
          css={css`
            margin: 0 auto;
          `}
        >
          <AppIcon iconUrl={app.icon} />
          <h2
            css={css`
              font-size: 32px;
              text-align: center;
              font-weight: normal;
            `}
          >
            {title}
          </h2>
          <Alert
            showIcon
            message={
              <>
                This app does not have an active subscription. Please contact
                the app owner ({owner.label}) to reactivate the subscription.
              </>
            }
          />
        </div>
      ) : (
        <div
          css={css`
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <Spinner />
        </div>
      )}
    </Modal>
  );
}

const getPayModalsInfo = (priceId: string) => {
  return {
    title: "Begin Your Free Trial",
    afterClose: () => removeUIState(),
    payButtonText: "Begin Free Trial",
    PriceAlert: (
      <>
        You will be charged $
        {priceId === products[stage].legacy.essentialNew.prices.monthly_58.id
          ? 58
          : 199}
        /month <b>after</b> your 7 day trial
      </>
    ),
    afterPay: () => {
      const { shouldCreateSubWithoutBuild } = store.getRawState();
      if (shouldCreateSubWithoutBuild) {
        message.success("Payment successful");
        removeUIState();
      }
    },
  };
};
