/// <reference types="@types/segment-analytics" />
import type { WindowsEVOnboardingProvider } from "@todesktop/shared";
import { store, selectedApp } from "../../store";
import { CertPlatform } from "../types";
import { IApp } from "@todesktop/shared";

declare global {
  interface Window {
    rudderanalytics: SegmentAnalytics.AnalyticsJS;
  }
}

interface IAnalyticsBaseProperties {
  client: string;
  app?: {
    id: string;
    name: string;
    url: string;
  };
}

function getAnalyticsBaseProperties() {
  const analyticsBaseProperties: IAnalyticsBaseProperties = {
    client: "webapp",
  };

  const state = store.getRawState();
  const app = selectedApp(state) || null;

  if (app) {
    analyticsBaseProperties.app = {
      id: app.id,
      name: app.name,
      url: app.url,
    };
  }

  return analyticsBaseProperties;
}

interface IStartedSigningUp {
  event: "Started Signing Up";
  properties: {
    provider: "password" | "google" | "twitter" | "github";
  };
}

interface IAbortedSigningUp {
  event: "Aborted Signing Up";
  properties: {
    provider: null;
  };
}

interface IErrorSigningUp {
  event: "Error On Signing Up";
  properties: { provider: string; error: string };
}

interface ISignedUp {
  event: "Signed Up";
  properties: { provider: "password" | "google" | "twitter" | "github" };
}

interface ISubscriptionCancel1 {
  event: "Subscription Cancel Request (1/2)";
  properties?: null;
}

interface ISubscriptionCancel2 {
  event: "Subscription Cancel Request (2/2)";
  properties?: null;
}

interface ISubscriptionCancelledSuccessfully {
  event: "Subscription Cancelled Successfully";
  properties?: null;
}

interface IOpenExternalLink {
  event: "Open External Link";
  properties: { [key: string]: unknown };
}

interface IAbortedSelectingPlan {
  event: "Aborted Selecting Plan";
  properties?: null;
}

interface IBeganTrial {
  event: "Began Trial";
  properties: {
    plan: string;
    existingCustomer: boolean;
  };
}

interface IErrorBeginningTrial {
  event: "Got Error On Beginning Trial";
  properties: {
    plan: string;
    existingCustomer: boolean;
    error: string;
  };
}

interface IAbortedBeginningTrial {
  event: "Aborted Beginning Trial";
  properties: {
    plan: string;
    existingCustomer: boolean;
  };
}

interface ISelectedPlan {
  event: "Selected Plan";
  properties: { plan: string };
}

interface IUpdateAppOption {
  event: "Update App Option";
  properties: {
    field: keyof IApp;
    checked: boolean;
  };
}

interface IUpdateAppTitle {
  event: "Update App Title";
  properties?: null;
}

interface IUpdateAppIcon {
  event: "Update App Icon";
  properties?: null;
}

interface IUpdateMenubarIcon {
  event: "Update Menubar Icon";
  properties?: null;
}

interface IUpdateTrayIcon {
  event: "Update Tray Icon";
  properties?: null;
}

interface IPreviewOS {
  event: "Preview OS";
  properties: { operatingSystem: string };
}

interface IUpdateWindowsDimensions {
  event: "Update Windows Dimensions";
  properties: { field: string; checked?: boolean };
}

interface IUpdateWindowsOption {
  event: "Update Windows Option";
  properties: { field: string; checked: boolean };
}

interface ISubmittedUrlForm {
  event: "Submitted URL Form";
  properties: {
    url: string;
    name?: string;
    host: string;
    source: string;
    type?: string;
  };
}

interface IBeganNewBuildProcess {
  event: "Began New Build Process";
  properties: { [key: string]: unknown };
}

interface ICopiedToClipboard {
  event: "Copied to clipboard";
  properties: { [key: string]: unknown };
}

interface ICreateDownloadButton {
  event: "Create Download Button";
  properties: {
    source: string;
  };
}

interface ICheckedChecklistItem {
  event: "Checked Checklist Item";
  properties: {
    item: string;
    checked: boolean;
  };
}

interface IChangedDownloadButtonTab {
  event: "Changed Download Button Tab";
  properties: { tab: string };
}

interface IChangedDownloadPreviewTab {
  event: "Changed Download Preview Tab";
  properties: { tab: string };
}

interface IUpdateButtonBuilderOption {
  event: "Update Button Builder Option";
  properties: { property: string; value: string };
}

interface IExpandedChecklistAccordion {
  event: "Expanded Checklist Accordion";
  properties: {
    checklistItem: string;
  };
}

interface IOpenFeedbackDialog {
  event: "Open Feedback Dialog";
  properties: {
    source: string;
  };
}

interface ICreateNewAppModal {
  event: "Create New App Modal";
  properties?: null;
}

interface IOfferPageFeedback {
  event: "Offer Page Feedback";
  properties: { message: string };
}

interface IGenerateNewToken {
  event: "Generate New Token";
  properties?: null;
}

interface IDownloadAppToDesktop {
  event: "Download App To Desktop";
  properties: {
    platform: string;
    label: string;
  };
}

interface IUpdateCustomDomain {
  event: "Update Custom Domain";
  properties: { action: "delete" | "save" };
}

interface IUpdateCertificate {
  event: "Update Certificate";
  properties: {
    type?: "certificate" | "notarization";
    action: "save" | "delete" | "manageModal" | "error";
    platform: CertPlatform;
    provider?: WindowsEVOnboardingProvider | "file";
  };
}

interface IToggleBuildPlatform {
  event: "Toggle Build Artifact";
  properties: { artifact: string; checked: boolean };
}

interface IReleaseBuild {
  event: "Release Build";
  properties: { action: "cancel" | "confirm" };
}

type Action =
  | IStartedSigningUp
  | IAbortedSigningUp
  | IErrorSigningUp
  | ISignedUp
  | ISubscriptionCancel1
  | ISubscriptionCancel2
  | ISubscriptionCancelledSuccessfully
  | IOpenExternalLink
  | IAbortedSelectingPlan
  | IBeganTrial
  | IErrorBeginningTrial
  | IAbortedBeginningTrial
  | ISelectedPlan
  | IUpdateAppOption
  | IUpdateAppTitle
  | IUpdateAppIcon
  | IUpdateMenubarIcon
  | IUpdateTrayIcon
  | IPreviewOS
  | IUpdateWindowsDimensions
  | IUpdateWindowsOption
  | ISubmittedUrlForm
  | IBeganNewBuildProcess
  | ICopiedToClipboard
  | ICreateDownloadButton
  | ICheckedChecklistItem
  | IChangedDownloadButtonTab
  | IChangedDownloadPreviewTab
  | IUpdateButtonBuilderOption
  | IExpandedChecklistAccordion
  | IOpenFeedbackDialog
  | ICreateNewAppModal
  | IOfferPageFeedback
  | IGenerateNewToken
  | IDownloadAppToDesktop
  | IUpdateCustomDomain
  | IUpdateCertificate
  | IToggleBuildPlatform
  | IReleaseBuild;

export const track = ({ event, properties = {} }: Action) =>
  window.rudderanalytics.track(event, {
    ...getAnalyticsBaseProperties(),
    ...properties,
  });

export const identify = (id: string, traits?: {}) =>
  window.rudderanalytics.identify(id, traits);

export const reset = () => window.rudderanalytics.reset();
