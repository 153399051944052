// libs
import React, { useState, useEffect } from "react";

// components
import { Tag, Modal, Button, message } from "antd";
import { DeleteOutlined, SyncOutlined } from "@ant-design/icons";
import { StyledAntdCard } from "../atoms/StyledAntdCard";
import { Space } from "../atoms/Space";
import { Flex, Anchor } from "../atoms/Primitives";
import { Heading } from "../atoms/Heading";
import { Text } from "../atoms/Text";
import { PlanBadge } from "./PlanBadge";
import { FormInput } from "../atoms/FormUtils";
import { HasFeatureGuard } from "./HasFeatureGuard";
import { CodeHighlighter } from "../atoms/CodeHighlighter";
import customDomainFeatureImg from "../../~reusables/images/customDomainFeature.svg";

// utils
import { callFirebaseFunction } from "../../~reusables/firebase";
import { IDomainCertificate } from "../../~reusables/types";
import { useStore, selectedApp, appHasActiveSubscription } from "../../store";
import { track } from "../../~reusables/util/analytics";
import useForm from "../../~reusables/hooks/useForm";
import { customDomainValidation } from "../../~reusables/util/validationRules";
import { updateUIState } from "../../~reusables/actions";
import { legacyProPlan } from "@todesktop/shared";

const checkCustomDomain = callFirebaseFunction("checkCustomDomain");
const addCustomDomain = callFirebaseFunction("addCustomDomain");
const deleteCustomDomain = callFirebaseFunction("deleteCustomDomain");

const initialLoaderState = {
  isLoading: false,
  loadingMessage: "",
};

export const DomainsCard: React.FC = () => {
  return (
    <StyledAntdCard
      title={
        <Space direction="column" align="flex-start">
          <Flex alignItems="center">
            <Heading variant="h4" as="h4" mr={5}>
              Domains
            </Heading>
            <PlanBadge
              plan={legacyProPlan}
              tooltip="Custom domains are only available on the professional plan."
            />
          </Flex>
          <Text color="support">
            Custom domains allow you to serve production releases from your
            domain.
          </Text>
        </Space>
      }
    >
      <CustomDomain showSubtitle={false} />
    </StyledAntdCard>
  );
};

export const DomainsModal: React.FC<{
  showModal: boolean;
  setShowModal: (value: React.SetStateAction<boolean>) => void;
}> = ({ showModal, setShowModal }) => {
  return (
    <Modal
      centered
      footer={null}
      visible={showModal}
      onCancel={() => setShowModal(false)}
      closable
    >
      <Heading textAlign="center" pb={5} variant="h3" as="h3">
        Manage your custom domain
      </Heading>
      <CustomDomain />
    </Modal>
  );
};

export const CustomDomain: React.FC<{ showSubtitle?: boolean }> = ({
  showSubtitle = true,
}) => {
  const [domainStatus, setDomainStatus] = useState<IDomainCertificate>();
  const [loader, setLoader] = useState(initialLoaderState);
  const { customDomain, id, appType, schemaVersion } = useStore(selectedApp);
  const doesAppHaveActiveSub = useStore(appHasActiveSubscription);

  const setSignUpUIState = () => {
    if (appType === "electron") {
      updateUIState("subscribe");
    } else if (schemaVersion >= 2) {
      updateUIState("pay-todesktop-builder");
    } else {
      updateUIState("create-sub-without-build");
    }
  };

  const callback = async (form: { customDomain: string }) => {
    if (!doesAppHaveActiveSub) {
      return setSignUpUIState();
    }

    const newDomain = form.customDomain.replace(/^www\./, "");

    setLoader({
      isLoading: true,
      loadingMessage: "Saving custom domain",
    });

    try {
      track({ event: "Update Custom Domain", properties: { action: "save" } });
      const res = await addCustomDomain({ appId: id, customDomain: newDomain });
      setDomainStatus(res.data);
      message.success("Custom domain saved");
    } catch (err) {
      message.error(err.message);
    }

    setLoader(initialLoaderState);
  };

  const deleteDomain = () => {
    Modal.confirm({
      title: "Are you sure you want to remove this custom domain?",
      content: "This action can not be undone.",
      async onOk() {
        setLoader({
          isLoading: true,
          loadingMessage: "Removing custom domain",
        });
        try {
          track({
            event: "Update Custom Domain",
            properties: { action: "delete" },
          });
          await deleteCustomDomain({ appId: id, customDomain });
          editValue({ customDomain: "" });
          setDomainStatus(null);
          message.success("Domain successfully removed");
        } catch (err) {
          message.error(err.message);
        }
        setLoader(initialLoaderState);
      },
      okText: "Yes, Remove",
      okType: "danger",
      cancelText: "No",
    });
  };

  const doCheckDomain = React.useCallback(() => {
    setLoader({
      isLoading: true,
      loadingMessage: "Checking domain",
    });
    checkCustomDomain({ appId: id, customDomain })
      .then((domainData) => {
        setDomainStatus(domainData.data);
      })
      .catch(() => setDomainStatus(null))
      .finally(() => setLoader(initialLoaderState));
  }, [customDomain, id]);

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    isSubmitting,
    formError,
    editValue,
  } = useForm({ customDomain }, callback, customDomainValidation);

  useEffect(() => {
    if (customDomain) {
      doCheckDomain();
    }
  }, [customDomain, doCheckDomain]);

  useEffect(() => {
    if (formError) {
      message.error(formError);
    }
  }, [formError]);

  return (
    <>
      {showSubtitle && (
        <Text pb={6} color="support">
          Custom domains allow you to serve production releases from a domain of
          your choice.
        </Text>
      )}
      {!doesAppHaveActiveSub && (
        <Text pb={6} color="support">
          Please{" "}
          <Anchor onClick={() => setSignUpUIState()}>
            start a professional subscription
          </Anchor>{" "}
          to create a custom domain
        </Text>
      )}
      <HasFeatureGuard
        src={customDomainFeatureImg}
        alt="Input box for typing in a custom domain"
        name="Custom domain"
        description="Serve your application downloads from a custom domain of your choice."
        plan={legacyProPlan}
      >
        <form onSubmit={handleSubmit}>
          <FormInput
            name="customDomain"
            disabled={!!domainStatus}
            label={
              domainStatus && (
                <>
                  <Tag
                    style={{ marginBottom: 12 }}
                    color={domainStatus?.status === "active" ? "green" : "blue"}
                  >
                    Status: {domainStatus?.status}{" "}
                    {domainStatus?.verification_errors?.[0] &&
                      `(${domainStatus?.verification_errors?.[0]})`}{" "}
                    {(isSubmitting || loader.isLoading) && (
                      <SyncOutlined spin />
                    )}
                  </Tag>
                </>
              )
            }
            size="large"
            placeholder="download.domain.com"
            error={errors.customDomain}
            value={values.customDomain}
            onChange={handleChange}
            suffix={
              <Space>
                {customDomain ? (
                  <Button
                    type="link"
                    danger
                    onClick={() => deleteDomain()}
                    icon={<DeleteOutlined />}
                  >
                    Delete domain
                  </Button>
                ) : (
                  <Button
                    type="link"
                    htmlType="submit"
                    loading={isSubmitting || loader.isLoading}
                  >
                    {loader.loadingMessage || "Save domain"}
                  </Button>
                )}
                {isSubmitting || loader.isLoading || !customDomain || (
                  <Button icon={<SyncOutlined />} onClick={doCheckDomain}>
                    Refresh status
                  </Button>
                )}
              </Space>
            }
          />
        </form>
      </HasFeatureGuard>
      {customDomain && domainStatus && domainStatus.status === "pending" && (
        <Space direction="column">
          <Text variant="body3" color="support" display="flex">
            Create a CNAME record to connect your application
          </Text>
          <CodeHighlighter
            showCopyIcon={false}
            codeString={`${customDomain} => cdn.todesktop.cloud`}
          />
        </Space>
      )}
    </>
  );
};
