// modules
import css from "@emotion/css";
import React from "react";

// components
import { Tooltip } from "antd";
import { StarOutlined } from "@ant-design/icons";
import { useTheme } from "../../~reusables/contexts/ThemeContext";
import { Heading } from "../atoms/Heading";
import { useStore, $hasPlanAccess } from "../../store";
import { Plan } from "@todesktop/shared";

interface IPlanBadge {
  plan: Plan;
  tooltip?: string;
  showLabel?: boolean;
}

export const PlanBadge: React.FC<IPlanBadge> = ({
  plan,
  tooltip,
  showLabel = true,
}) => {
  const hasPlanAccess = useStore($hasPlanAccess(plan));
  const { space } = useTheme();

  // if you don't have an active subscription or access to this feature, show the label
  if (!hasPlanAccess) {
    return (
      <Tooltip
        title={
          tooltip || `This feature is only available on the ${plan.label} plan.`
        }
      >
        <div
          css={css`
            display: inline-flex;
            align-items: center;
          `}
        >
          <StarOutlined style={{ marginRight: space[3], color: "#f9c606" }} />
          {showLabel && (
            <Heading variant="h6" as="h6" color="#f9c606">
              {plan.label}
            </Heading>
          )}
        </div>
      </Tooltip>
    );
  }

  return null;
};
