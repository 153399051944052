import { CheckOutlined } from "@ant-design/icons";
import { BaseApp, Product, products } from "@todesktop/shared";
import { Button, Divider, Modal, Switch, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { selectedApp, useStore } from "../../store";
import {
  createCustomerCheckoutSession,
  removeUIState,
  updateUIState,
} from "../../~reusables/actions";
import { stage } from "../../~reusables/firebase/config";
import { history } from "../../~reusables/util";
import { Flex } from "../atoms/Primitives";

const { Title, Text } = Typography;

export const SubscribeModal: React.FC = () => {
  const app = useStore(selectedApp);
  const { uiState, user } = useStore((state) => ({
    uiState: state.uiState,
    user: state.user,
  }));

  useEffect(() => {
    if (user) return;
    updateUIState("convert-login");
  }, [user]);

  return (
    <Modal
      open={uiState === "subscribe"}
      width={676}
      footer={null}
      closable={true}
      centered={true}
      onCancel={() => {
        if (app.appType === "electron") {
          Modal.confirm({
            title: "Closing this modal will redirect you back home",
            onOk() {
              history.push("/");
              removeUIState();
            },
          });
        } else {
          removeUIState();
        }
      }}
    >
      {app?.appType === "electron" && <CLIPlans app={app} />}
    </Modal>
  );
};

const CLIPlans: React.FC<{
  app: BaseApp;
}> = () => {
  return (
    <Plans
      defaultProductId={products[stage].cli.performance.id}
      products={[
        {
          label: "Essential",
          product: products[stage].cli.founder,
          features: [
            {
              label: (
                <>
                  <strong>35</strong> builds/month
                </>
              ),
            },
            {
              label: (
                <>
                  <strong>30 day</strong> build retention
                </>
              ),
            },
            {
              label: (
                <>
                  <strong>5000</strong> downloads/month
                </>
              ),
            },
            { label: "Windows, Mac & Linux build servers" },
            { label: "Code signing" },
            { label: "Auto updates" },
            { label: "Native app installers" },
            { label: "Native module rebuilding" },
            { label: "Automated Electron code analysis" },
            { label: "CDN-backed downloads/updates" },
            { label: "Download links on your domain" },
          ],
        },
        {
          label: "Performance",
          product: products[stage].cli.performance,
          features: [
            {
              label: (
                <>
                  <strong>120</strong> builds/month
                </>
              ),
            },
            {
              label: (
                <>
                  <strong>60 day</strong> build retention
                </>
              ),
            },
            {
              label: (
                <>
                  <strong>15,000</strong> downloads/month
                </>
              ),
            },
            { label: "Staging and dev apps" },
            { label: "Auto-update smoke testing" },
            { label: "Windows & Mac web installers" },
            { label: "Private Slack-channel support" },
            { label: "MSI and PKG installers" },
            { label: "Microsoft and Mac app store" },
            { label: "Download analytics" },
            { label: "Build analytics" },
          ],
        },
        {
          label: "Scale",
          product: products[stage].cli.scale,
          features: [
            {
              label: (
                <>
                  <strong>1,000</strong> builds/month
                </>
              ),
            },
            {
              label: (
                <>
                  <strong>90 day</strong> build retention
                </>
              ),
            },
            {
              label: (
                <>
                  <strong>60,000</strong> downloads/month
                </>
              ),
            },
            { label: "Support for staggered rollouts" },
            { label: "Account manager" },
            { label: "Priority support & onboarding" },
            { label: "Integration assistance" },
          ],
        },
      ]}
    />
  );
};

interface PlansProps {
  defaultProductId: string;
  products: {
    label: string;
    product: Product;
    features: { label: React.ReactNode }[];
  }[];
}

interface PlanState {
  loading: boolean;
  selectedProductId: string;
  period: "monthly" | "yearly";
}

const Plans: React.FC<PlansProps> = ({ defaultProductId, products }) => {
  const app = useStore(selectedApp);
  const [state, setState] = useState<PlanState>({
    selectedProductId: defaultProductId,
    loading: false,
    period: "yearly",
  });

  return (
    <Flex css={{ flexDirection: "column", alignItems: "center" }}>
      <Title level={3}>Begin Your Free Trial</Title>
      <Flex css={{ gap: "12px" }}>
        {products.map(({ label, product, features }, i) => {
          const price = Object.values(product.prices).find(
            (price) =>
              price.status === "active" && price.period === state.period
          );

          const isYearly = state.period === "yearly";
          const amount = isYearly ? price.amount / 12 : price.amount;
          const previousProduct = products[i - 1];

          return (
            <Flex
              key={product.id}
              css={{
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "16px",
                borderRadius: "4px",
                background: product.id === defaultProductId ? "#F8F8F8" : "",
              }}
            >
              <Flex css={{ flexDirection: "column" }}>
                <Title level={5} style={{ marginBottom: 4 }}>
                  {label}
                </Title>
                <Flex css={{ alignItems: "center", gap: "6px" }}>
                  <Text>${amount}/month</Text>
                  {isYearly && (
                    <Tag
                      color="green"
                      style={{ border: "0", fontWeight: "bold" }}
                    >
                      -20%
                    </Tag>
                  )}
                </Flex>
                <Flex
                  css={{ paddingTop: "8px", alignItems: "center", gap: "4px" }}
                >
                  <Switch
                    size="small"
                    checked={isYearly}
                    onChange={(checked) => {
                      setState((prev) => ({
                        ...prev,
                        period: checked ? "yearly" : "monthly",
                      }));
                    }}
                  />
                  <Text type="secondary">Annual billing</Text>
                </Flex>
                <Divider />
                <Flex css={{ flexDirection: "column", gap: "6px" }}>
                  <Text type="secondary" style={{ fontSize: "11px" }}>
                    {previousProduct
                      ? `Everything in ${previousProduct.label}, plus:`
                      : `${label} plan includes:`}
                  </Text>
                  {features.map(({ label }, i) => {
                    return (
                      <Flex
                        key={i}
                        css={{ alignItems: "baseline", gap: "6px" }}
                      >
                        <CheckOutlined style={{ fontSize: 10 }} />
                        <Text style={{ fontSize: 12 }}>{label}</Text>
                      </Flex>
                    );
                  })}
                </Flex>
              </Flex>
              <Flex css={{ flexDirection: "column" }}>
                <Divider />
                <Button
                  shape="round"
                  disabled={state.loading}
                  loading={
                    state.loading && state.selectedProductId === product.id
                  }
                  onClick={async () => {
                    setState((prev) => ({
                      ...prev,
                      selectedProductId: product.id,
                      loading: true,
                    }));
                    await createCustomerCheckoutSession({
                      priceId: price.id,
                      appId: app.id,
                    });
                    setState((prev) => ({ ...prev, loading: false }));
                  }}
                >
                  Get started
                </Button>
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};
